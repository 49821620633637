import React from 'react'
import { upskillSectionFourContent } from '../../data/upskill'

export const UpskillSectionFour = () => {
  return (
      <section className='h-auto relative mx-auto w-11/12 my-14 lg:my-[80px]'>
        {/* <div className='absolute gradient z-[-1] h-[100%] w-[100%] rounded-[25px]'></div> */}
        <div className='absolute  z-[-1] h-[100%] w-[100%] rounded-[25px]'></div>
          <div className='px-8 py-10 lg:py-14'>
              <div className='xl:w-9/12 mx-auto'>
                    <h1 className='text-[30px] lg:text-[38px] lg:text-center font-[700]'>{upskillSectionFourContent.title.normal}<span className='text-[#0361FE]'>{upskillSectionFourContent.title.coloured}</span>{upskillSectionFourContent.title.normalContinuation}<span className='text-[#0361FE]'>{upskillSectionFourContent.title.colourContinuation}</span></h1>
                  <p className='text-[16px] font-[400] lg:text-center py-6 leading-[30px]'>{upskillSectionFourContent.paraLine}</p>
              </div>
              <div className='w-11/12 mx-auto mt-6'>
                  <div className="flex flex-col md:flex-row md:space-x-5 md:justify-evenly">
                      {
                          upskillSectionFourContent.listDataOne.map((data, index) => {
                              return (
                                  <div className={`space-y-2 flex flex-col items-center ${index!==upskillSectionFourContent.listDataOne.length-1 && "mb-14 md:mb-0"}`} key={index}>
                                      <img src={data.icon} alt={data.heading} />
                                      <h4 className='text-[15px] font-[700]'>{data.heading}</h4>
                                      <p className='lg:w-[200px] text-[14px] xl:w-[230px] text-center'>{data.text}</p>
                                  </div>
                              )
                          })
                      }
                  </div>
                  <div className="flex flex-col md:flex-row mt-14 md:space-x-5 md:justify-evenly">
                      {
                          upskillSectionFourContent.listDataTwo.map((data, index) => {
                              return (
                                  <div className='space-y-2 flex flex-col items-center mb-14 md:mb-0' key={index}>
                                      <img src={data.icon} alt={data.heading} />
                                      <h4 className='text-[15px] font-[700]'>{data.heading}</h4>
                                      <p className='lg:w-[200px] text-[14px] xl:w-[230px] text-center'>{data.text}</p>
                                  </div>
                              )
                          })
                      }
                  </div>
              </div>
        </div>
    </section>
  )
}
