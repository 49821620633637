import React from 'react'
import { upskillSectionTwoContent } from '../../data/upskill'

export const UpskillSectionTwo = () => {
  return (
      <section className='bg-[#0361FE] relative  p-6 py-10 lg:py-16 lg:p-12 flex flex-col lg:flex-row lg:space-x-4'>
          <div className='lg:w-1/2 text-white space-y-8 lg:p-6'>
              <h3 className='text-[35px] md:text-[42px] font-[600] text-black'>{upskillSectionTwoContent.boxOne.title.blackColor}<span className='text-white'>{upskillSectionTwoContent.boxOne.title.whiteColor}</span></h3> 
              <p className='text-[18px] xl:w-10/12'>{upskillSectionTwoContent.boxOne.firstParaLine}</p>
              <p className='text-[18px] xl:w-10/12'>{upskillSectionTwoContent.boxOne.secondParaLine}</p>
          </div>
          <div className='lg:w-1/2 mt-10'>
              {
                  upskillSectionTwoContent.boxTwo.map((data, index) => {
                      return (
                          <div key={index} className="text-white flex items-center space-x-5 pb-6">
                              <img src={upskillSectionTwoContent.image} />
                              <p>{data}</p>
                          </div>
                      )
                  })
              }
          </div>
    </section>
  )
}
