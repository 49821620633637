import onBoardBannerShowImage from "../images/dashboardmac.png";
import arrowWithImage from "../images/arrowWithBox.svg";
import upskillSectionTwoCrop from "../images/upskillSectionTwoCrop.svg";
import upskillLiveClassIcon from "../images/upskillLiveClassIcon.svg";
import upskillMobileIcon from "../images/upskillMobileIcon.svg";
import upskillMultilingualIcon from "../images/upskillMultilingualIcon.svg";
import upskillGameIcon from "../images/upskillGameIcon.svg";
import upskillContectualIcon from "../images/upskillContectualIcon.svg";
import upskillNoticeIcon from "../images/upskillNoticeIcon.svg";
import upskillQuizesIcon from "../images/upskillQuizesIcon.svg";
import upskillRealTimeIcon from "../images/upskillRealTimeIcon.svg";


export const upskillBannerContent = {
    title: "Augment the knowledge and competencies for upskilling employees' through best microlearning platform",
    paraLine: "Organisations are by the people and for the people. Encourage purposeful and lifelong learning and professional development to spur creativity at your company with the help of TalntX, the best microlearning platform.",
    image: onBoardBannerShowImage,
    button:"Book a Demo"
}
export const upskillSectionTwoContent = {
    boxOne: {
        title: {
            blackColor: "Ready, Set, ",
            whiteColor:"Upskill"
        },
        firstParaLine: "Our learning program chalks out learning initiatives for employees mandatory for the ever- changing landscape of your organisation. This upskilling contributes to better returns on investment, higher employee retention rate and enhanced productivity.",
        secondParaLine:"We promote internal mobility and skill development for your employees to fulfil organizational goals. See how analytics and skill data on our platform for learning and upskilling lets everything run smoothly."
    },
    boxTwo: [
        "Pertinent courses to empower the workforce",
        "Notification alerts remind trainees to complete the work",
        "Develop skills that fit in different environments",
        "Platforms for growth that will prod them towards learning",
        "Ameliorated productivity from staff as they embark on learning process"
    ],
    image:arrowWithImage
}

export const upskillSectionThreeContent = {
    title: {
        coloured: "Microlearning Platform ",
        normal:"by Talntworx"
    },
    paraLine: "Encourage reflection and skill benchmarking to motivate and advance improvement in the workforce of the company.",
    listData: [
        {
            num: "55M+",
            text:"minutes of interaction"
        },
        {
            num: "70%",
            text:"enhancement in productivity"
        },
        {
            num: "80%",
            text:"affordability"
        },
        {
            num: "8x-10x",
            text:"increased learning effectiveness"
        },
        {
            num: "8x-10x",
            text:"quicker content creation"
        },
        {
            num: "500K+",
            text:"workforce upskilled"
        }
    ],
    paraLineTwo: "Our microlearning platform gives everyone an  instrument to enhance their special talents and interests. Curated learning journeys combine information, experiences, and assessments.",
    image:onBoardBannerShowImage
}

export const upskillSectionFourContent = {
    title: {
        normal: "Why ",
        coloured: "choose us to empower ",
        normalContinuation: "the workforce at ",
        colourContinuation:"your company?"
    },
    paraLine: "Fueled by the robust in-industry experience of 7 long years, we have developed an expertise and outlook on how to upskill the workforce. Your staff may own their learning and professional development with the help of our learning experience platform, which leads to quicker business adaptation. TalntX microlearning platform centralizes all the information your employees require to develop their talents for the future.",
    listDataOne: [
        {
            icon: upskillMobileIcon,
            text: "Maximum reach with the least amount of human involvement, even in areas with limited bandwidth.",
            heading:"Mobile-first experience"
        }, 
        {
            icon: upskillMultilingualIcon,
            text: "Inclusivity in terms of multiple languages offered.",
            heading:"Multilingual"
        }, 
        {
            icon: upskillGameIcon,
            text: "Gamification to upskill frontline workers.",
            heading:"Gamified"
        }, 
        {
            icon: upskillNoticeIcon,
            text: "Encrypted interactive groups to enhance engagement.",
            heading:"Interactive notice boards"
        }
    ],
    listDataTwo: [
        {
            icon: upskillLiveClassIcon,
            text: "Live classes on different platforms and active notification alerts for pending tasks.",
            heading:"Live Classes & Calendar Integration"
        },
        {
            icon: upskillRealTimeIcon,
            text: "Access to real time data analytics for deeper insights.",
            heading:"Real-time analytics"
        },
        {
            icon: upskillQuizesIcon,
            text: "Fun assessments to learn about current happenings in the industry",
            heading:"Daily updates & quizzes"
        },
        {
            icon: upskillContectualIcon,
            text: "Well-drafted and timely delivery of relatable content.",
            heading:"Contextual learning"
        },
    ]
}