import React from 'react'
import { upskillBannerContent } from '../../data/upskill'
import { useModal } from '../ContactForm'

export const UpskillBanner = () => {
  const { modalAction } = useModal();
  return (
      <section className='lg:h-[550px] 2xl:h-[680px] p-6 lg:p-10'>
          {/* <h1 className='text-[#0361FE] text-[35px] lg:text-[56px] font-[700]'>{upskillBannerContent.title}</h1> */}
          <h1 className='purple_to_gradient text-[35px] lg:text-[56px] font-[700]'>{upskillBannerContent.title}</h1>

          <div className='flex flex-col lg:flex-row'>
              <div className='lg:w-1/2 space-y-6 py-4 lg:py-8'>
                  <p className='lg:w-11/12 2xl:w-8/12 lg:leading-[30px]'>{upskillBannerContent.paraLine}</p>
                  <button className='bg-[#0361FE] text-white py-[11px] px-[25px] rounded-[48px]' onClick={modalAction}>{upskillBannerContent.button}</button>
              </div>
              <img src={upskillBannerContent.image} alt="upskillBannerImage" className='lg:w-1/2 object-cover rounded-[8px]'/>
          </div>
    </section>
  )
}
